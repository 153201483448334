<template>
<div>
  <div style="width: 60%;text-align: left;padding: 50px;margin-left:50px">
    <p>黑猫货态查询：<el-link href="https://www.t-cat.com.tw/Inquire/trace.aspx" target="_blank" type="primary">https://www.t-cat.com.tw/Inquire/trace.aspx</el-link></p>
    <p>新竹货态查询：<el-link href="https://www.hct.com.tw/Search/SearchGoods_n.aspx" target="_blank" type="primary">https://www.hct.com.tw/Search/SearchGoods_n.aspx</el-link></p>
    <p>大荣货态查询：<el-link href="http://www.kerrytj.com/zh/search/search_track.aspx" target="_blank" type="primary">http://www.kerrytj.com/zh/search/search_track.aspx</el-link></p>
    <p>东风货态查询：<el-link href="http://east-wind.com.tw" target="_blank" type="primary">http://east-wind.com.tw</el-link></p>
    <p>7-11货态查询：<el-link href="https://eservice.7-11.com.tw/E-Tracking/search.aspx" target="_blank" type="primary">https://eservice.7-11.com.tw/E-Tracking/search.aspx</el-link></p>
    <p>全家货态查询：<el-link href="https://www.famiport.com.tw/Web_Famiport/page/process.aspx" target="_blank" type="primary">https://www.famiport.com.tw/Web_Famiport/page/process.aspx</el-link></p>
    <p>OK货态查询：<el-link href="https://ecservice.okmart.com.tw/Tracking/Search" target="_blank" type="primary">https://ecservice.okmart.com.tw/Tracking/Search</el-link></p>
    <p>萊爾富货态查询：<el-link href="https://www.hilife.com.tw/serviceInfo_search.aspx" target="_blank" type="primary">https://www.hilife.com.tw/serviceInfo_search.aspx</el-link></p>
    <p>虾皮宅配货态查询：<el-link href="http://query2.e-can.com.tw/ECAN_APP/search.shtm" target="_blank" type="primary">http://query2.e-can.com.tw/ECAN_APP/search.shtm</el-link></p>
    <p>虾皮店到店货态查询：<el-link href="https://spx.tw/" target="_blank" type="primary">https://spx.tw/</el-link></p>
    <p>露天订单查询：<el-link href="https://b2b.cvs.com.tw/RutenOrderSearch/" target="_blank" type="primary">https://b2b.cvs.com.tw/RutenOrderSearch/</el-link></p>
    <p>7-11门市查询：<el-link href="https://emap.pcsc.com.tw/emap.aspx" target="_blank" type="primary">https://emap.pcsc.com.tw/emap.aspx</el-link></p>
    <p>全家门市查询：<el-link href="https://www.family.com.tw/marketing/inquiry.aspx" target="_blank" type="primary">https://www.family.com.tw/marketing/inquiry.aspx</el-link></p>
    <p>萊爾富门市查询：<el-link href="http://www.hilife.com.tw/storeInquiry_street.aspx" target="_blank" type="primary">http://www.hilife.com.tw/storeInquiry_street.aspx</el-link></p>
    <!-- <p>门店号参考资料：<el-button type="primary" plain size="mini" @click="docDownload">下载</el-button></p> -->
    <p>佳博打印机驱动：<el-button type="primary" plain size="mini" @click="jbDownload">下载驱动</el-button></p>
    <p>得力new系列：<el-button type="primary" plain size="mini" @click="dlDownloadNew">下载驱动</el-button></p>
    <p>得力不带new系列：<el-button type="primary" plain size="mini" @click="dlDownload">下载驱动</el-button></p>
    <!-- <p>快递单号自动获取插件：<el-button type="primary" plain size="mini" @click="chromeDownload">下载</el-button></p> -->
    <p>快捷采购插件：<el-button type="primary" plain size="mini" @click="chromeDownload">下载</el-button></p>
    <p>采购插件补丁：<el-button type="primary" plain size="mini" @click="chromeBdDownload">下载</el-button></p>
  </div>
</div>
</template> 

<script>

export default {
  name: 'dashboard',
  components: {
  
  },
  mounted:function(){
    
  },
  data(){
    return{
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
  },
  methods: {
      jbDownload(){
          window.open(this.$kit.api.jbDownload,"_blank")
        },
      dlDownload(){
          window.open(this.$kit.api.dlDownload,"_blank")
      },
      dlDownloadNew(){
          window.open(this.$kit.api.dlDownloadNew,"_blank")
      },
      docDownload(){
          window.open(this.$kit.api.docDownload,"_blank")
      },
      chromeDownload(){
        window.open(this.$kit.api.chromeDownload,"_blank")
      },
      chromeBdDownload(){
        window.open(this.$kit.api.chromeBdDownload,"_blank")
      },
  }
}
</script>

<style scoped lang="scss">

</style>
