<!-- 联系方式 -->
<template>
<div style="height:800px">
  <div style="width: 50%;text-align: left;padding: 50px;margin:0px;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
    <!-- <p style="font-size:30px;font-weight:bold;">{{company}}</p> -->
    <!-- <p>{{msg('联系方式1')}}</p>
    <p>{{msg('联系方式2')}}</p>
    <p>{{msg('联系方式3')}}</p>
    <p>{{msg('联系方式4')}}</p> -->
    <p>{{company?tel1:'？？？未开通'}}</p>
    <p>{{company?tel2:'？？？未开通'}}</p>
    <p>{{company?tel3:'？？？未开通'}}</p>
  </div>
</div>
</template> 

<script>

export default {
  name: 'dashboard',
  components: {
  
  },
  mounted:function(){
    this.getDetail();
  },
  data(){
    return{
      tel1:null,
      tel2:null,
      tel3:null,
      company:null,
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height;
        }
    },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
  },
  methods: {
    getDetail(){
       this.$axios.get(this.$kit.api.dept.getDetail)
          .then( (result) => {
              let data = result.data;
              this.tel1 = data.tel1;
              this.tel2 = data.tel2;
              this.tel3 = data.tel3;
              this.company = data.company;
          })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
